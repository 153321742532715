import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

// TODO: make this a flexgrid kinda thing... rather than bullets have them as a card each, with a tiny logo in it
export default function Home({ data }) {
  const image = data.contentfulAsset
  return (
    <Layout title="Home">
      <h1>Mike is...</h1>
      <ul>
        <li>
          <OutboundLink href="https://www.linkedin.com/in/themcclure/">
            A technology professional
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://roastedby.themcclure.com">
            An amateur coffee roaster
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://artifonica.com">
            An amateur musician
          </OutboundLink>
        </li>
        <li>Hoping to trade some of his coffee for CSS help :)</li>
        <li>
          A memoji
          <br />
          <GatsbyImage alt="memoji" image={getImage(image)} width={128} />
        </li>
      </ul>
    </Layout>
  )
}

export const memoji = graphql`
  query Memoji {
    contentfulAsset(title: { eq: "Memoji" }) {
      gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, width: 240)
      description
    }
  }
`
